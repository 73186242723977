@font-face {
  font-family: "Roboto-Bold";
  src: url("../fonts/Roboto-Bold.woff2") format('woff2'),
       url("../fonts/Roboto-Bold.woff") format('woff');
  font-display: swap;

}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../fonts/Roboto-Medium.woff2") format('woff2'),
       url("../fonts/Roboto-Medium.woff") format('woff');
  font-display: swap;

}

@font-face {
  font-family: "Roboto-Regular";
  src: url("../fonts/Roboto-Regular.woff2") format('woff2'),
       url("../fonts/Roboto-Regular.woff") format('woff');
  font-display: swap;

}

html {
  scroll-behavior: smooth;
  -ms-scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
  counter-reset: my-sec-counter;
  -ms-scroll-padding-top: 125px;
  /* IE/Edge */
  -webkit-scroll-padding-tope: 125px;
  /* Safari */
}

body {
  font-family: "Roboto-Regular";
  counter-reset: my-sec-counter;
  -ms-scroll-padding-top: 125px;
  /* IE/Edge */
  -webkit-scroll-padding-tope: 125px;
  /* Safari */
}

/* Core override */
.wv-social-link a:last-child {
  display: none !important;
}

.--no-list-style {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Buttons */

.btn-primary {
  background: #314af2;
  color: #ffffff;
  border-radius: 8px;
  font-size: 14px;
}

.btn {
  padding: 8px 15px;
  min-width: 140px;
}

.btn-secondary {
  background: #333333 0% 0% no-repeat padding-box;
  color: #ffffff;
}

.btn-large {
  min-width: 260px;
  padding: 15px;
}

.btn-w200 {
  min-width: 190px;
}

/* Typo */

.wv-heading-h2 {
  font-size: 34px;
  line-height: 28px;
  margin: 0;
}

.wv-heading-h3 {
  font-size: 30px;
  line-height: 28px;
  margin: 0;
}

.wv-content-section .wv-heading-h4 {
  font-size: 22px;
  line-height: 28px;
  color: #333333;
  font-family: "Roboto-Medium";
  margin: 0;
  margin-bottom: 20px !important;
}

.wv-heading-h5 {
  font-size: 16px;
  line-height: 28px;
  margin: 0;
}

.wv-lg-heading {
  letter-spacing: -0.8px;
  font-size: 40px;
  line-height: 50px;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 30px;
}

.wv-w90 {
  width: 90%;
}

.wv-sm-heading {
  font-size: 14px;
  line-height: 24px;
  color: #333333;
}

.wv-sm-heading-5F6368 {
  color: #5f6368;
}

.wv-sm-heading-5F6368:hover {
  color: #333;
}

.wv-tab-menu .row > div.current .wv-sm-heading-5F6368 {
  color: #333;
}

.wv-md-heading {
  font-size: 18px;
  line-height: 32px;
  margin: 0;
}

.wv-md-heading1 {
  font-size: 16px;
  line-height: 28px;
  margin: 0;
}
.wv-limited-content {
  max-width: max-content;
  padding-right: 40px;
}

.wv-text-primary {
  color: #333333;
}

.wv-text-link {
  color: #4095e9;
  font-weight: lighter;
  text-decoration: underline;
}

.wv-weight-normal {
  font-weight: normal;
}

.wv-weight-medium {
  font-weight: 500;
}

/* Core Override */

.wv-nav-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 11;
  transition: ease 0.2s;
}

.wv-nav-up {
  top: -43px;
  transition: ease 0.2s;
  background: #fff;
  box-shadow: 0 -9px 12px 0px #7b7b7b;
}

.wv-nav-down {
  top: 0px;
  transition: ease 0.2s;
  background: #fff;
  box-shadow: 0 -9px 12px 0px #7b7b7b;
}

.wv-header {
  background: #333333 0% 0% no-repeat padding-box;
  height: 38px;
  display: flex;
  align-items: center;
}

.wv-header a {
  color: #9e9e9e;
  font-size: 15px;
  line-height: 18px;
  text-decoration: none;
  padding-top: 8px;
  display: block;
}

.wv-nav {
  padding: 20px 0;
  background-color: #fff;
  position: relative;
}

.wv-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  transition: ease 0.3s;
}

.wv-nav ul li {
  display: inline-block;
  margin: 0 15px;
}

.wv-nav ul li a {
  color: #333;
  font-size: 14px;
  text-transform: uppercase;
}

.wv-nav ul li a:hover {
  color: #3a4aff;
  text-decoration: none;
}

.wv-nav ul li:last-child {
  margin-right: 0;
}

.wv-brand {
  font-family: "Roboto", serif;
}

.wv-banner {
  background-color: #502287;
  background-size: cover;
}

.min-height-42 {
  min-height: 42px;
}

.wv-feature-page {
  background: transparent linear-gradient(0deg, #6190e8 0%, #a7bfe8 100%) 0% 0%
    no-repeat padding-box;
}

.wv-howit-work {
  background: transparent linear-gradient(0deg, #e0eafc 0%, #cfdef3 100%) 0% 0%
    no-repeat padding-box;
}

.wv-signon-section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 280px;
  text-align: center;
  margin-bottom: 20px;
}

.wv-howit-work .container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #333;
}

.wv-logo {
  max-width: 180px;
}

.wv-banner .container {
  height: 420px;
  position: relative;
}

.wv-banner-h320 > .container {
  height: auto;
  padding: 40px 0px;
  padding-top:10px;
}
.wv-banner-h320 > .container .form_below_content {
  width: 75%;
  float: left;
  padding-top: 0px;
  position: relative;
  top: 0px;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  left: 12.5%;
}
.wv-banner-h320 > .container .knowledge_header_form .basic_plans{
  display: none;
} 

.wv-banner .__content {
  position: absolute;
  bottom: 0;
  width: 555px;
  padding: 50px 60px;
  color: #fff;
}

.wv-zendesk .__content {
  border: 4px solid #bf4e5a;
  border-bottom: none;
}

.wv-first-section-zendesk .__content {
  border: 4px solid #bf4e5a;
  border-top: none;
}

.wv-freshdesk .__content {
  border: 4px solid #28caf4;
  border-bottom: none;
}
.wv-freshservice .__content {
  border: 4px solid #28caf4;
  border-bottom: none;
}

.wv-first-section-freshdesk .__content {
  border: 4px solid #28caf4;
  border-top: none;
}

.wv-ibm .__content {
  border: 4px solid #ce119d;
  border-bottom: none;
}

.wv-first-section-ibm .__content {
  border: 4px solid #ce119d;
  border-top: none;
}

.wv-office365 .__content {
  border: 4px solid #008e9f;
  border-bottom: none;
}

.wv-first-section-office365 .__content {
  border: 4px solid #008e9f;
  border-top: none;
}

.wv-servicenow .__content {
  border: 4px solid #00b5a1;
  border-bottom: none;
}

.wv-first-section-servicenow .__content {
  border: 4px solid #00b5a1;
  border-top: none;
}

.wv-twilio .__content {
  border: 4px solid #00a2ff;
  border-bottom: none;
}

.wv-first-section-twilio .__content {
  border: 4px solid #00a2ff;
  border-top: none;
}

.wv-active-directory .__content {
  border: 4px solid #003fbc;
  border-bottom: none;
}

.wv-first-section-active-directory .__content {
  border: 4px solid #003fbc;
  border-top: none;
}

.wv-banner .__content > h4 {
  font-size: 40px;
  line-height: 50px;
}

.wv-banner .__content > p,
.wv-first-section .__content > p {
  font-size: 18px;
  line-height: 32px;
}

.wv-first-section .__content {
  width: 555px;
  /* border: 4px solid #BF4E5A; */
  padding: 50px 60px;
}

.wv-main-content {
  padding: 10px 0 20px 0;
  width: 100%;
  float: left;
  position: relative;
}
.col-md-3.col-sm-12.col-xs-12.--sh-sm.mb-4 {
  position: sticky;
  top: 80px;
  z-index: 9;
  background: #fff;
}
.wv-sidenav {
  position: sticky;
  position: -webkit-sticky;
  top: 35%;
  z-index: 5;
  transition: ease 0.3s;
}

.wv-sidenav.-remove-sticky {
  position: relative;
  transition: ease 0.3s;
}

.wv-sidenav.--sticky {
  position: fixed;
  max-width: 255px;
  transition: ease 0.3s;
  top: 140px;
}

.wv-sidenav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 18px;
}

.wv-sidenav ul li {
  margin-bottom: 18px;
}

.wv-sidenav ul li a {
  color: #adadad;
}

.wv-sidenav.--number-heading ul li a:before {
  counter-increment: my-sec-counter + 1;
  content: "" counter(my-sec-counter) "";
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  margin-right: 13px;
  background-color: #adadad;
  border-radius: 100%;
  color: #fff;
  vertical-align: baseline;
  line-height: 32px;
}

.wv-sidenav.--number-heading ul li a.current:before {
  background-color: #4095e9;
}

.wv-sidenav ul li a.current {
  color: #4095e9 !important;
}

.wv-sidenav ul li a:hover {
  color: #4095e9;
  text-decoration: none;
}

.wv-content-area {
  padding: 0px 20px;
}

.wv-content-section:first-child {
  padding-top: 0;
}

.wv-sec-line {
  border-bottom: 1px solid #ebebeb;
  border-top: none;
  margin-top: 50px;
  margin-bottom: 50px;
}

.wv-text-with-line {
  color: #333333;
  line-height: 32px;
  font-size: 18px;
  padding: 10px 0;
  margin-bottom: 0;
}

.wv-text-with-line:first-child {
  padding-top: 0;
}

.wv-ico-content {
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 50px;
}

.wv-ico-content h5 {
  font-size: 20px;
  line-height: 28px;
  margin-top: 20px;
}

.wv-img-content {
  border-bottom: 1px solid #ebebeb;
}

.wv-img-content:last-child {
  border-bottom: none;
}

.wv-img-content .__data:first-child {
  border-right: 1px solid #ebebeb;
}

.wv-img-content .__data {
  padding: 50px;
}

.wv-img-content .__data > h5 {
  color: #4095e9;
  margin: 40px 0 30px 0;
}

.wv-img-content .__data > p {
  font-size: 16px;
  line-height: 24px;
  color: #333;
  margin-bottom: 40px;
  min-height: 125px;
}

.wv-img-content .__data > a {
  color: #4095e9;
  text-decoration: underline;
}

.wv-footer {
  background: #f7f8fa 0% 0% no-repeat padding-box;
  color: #333333;
  font-size: 14px;
  padding: 65px 0 10px 0;
  width: 100%;
  float: left;
}

.wv-footer .--logo {
  max-width: 180;
}

.wv-footer p {
  margin-bottom: 0px;
  line-height: 14px;
  color: #a7abb4;
  /* text-transform: lowercase; */
  font-size: 12px;
  font-family: "Roboto-Regular";
}

.--quick-link h4 {
  text-align: left;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  color: #1f3750;
  margin-bottom: 25px;
  font-family: "Roboto-Medium";
}

.--quick-link ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.--quick-link {
  margin-top: 20px;
}

.--quick-link ul li a {
  font-weight: normal;
  color: #4e5257;
  display: block;
  font-size: 14px;
  font-family: "Roboto-Regular";
  line-height: 32px;
}

.--quick-link ul li a:hover {
  color: #2d6df9;
  text-decoration: none;
}

.--footer-sublink ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.--footer-sublink ul li {
  display: inline-block;
  cursor: pointer;
  color: #5f6368;
}

.--footer-sublink ul li a {
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
  color: #a7abb4;
  font-family: "Roboto-Regular";
}
.footer_social_icons span {
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
  color: #a7abb4;
  font-family: "Roboto-Regular";
}
.--footer-sublink ul li a:hover {
  color: #333;
  text-decoration: none;
}

.--footer-sublink ul li {
  margin: 0 18px;
}

.--footer-sublink ul li:first-child {
  margin-left: 0;
}

.--footer-sublink ul li:last-child {
  margin-right: 0;
}

.wv-company-name.footer-content a img {
  cursor: pointer;
}

.--footer-border {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 15px;
}

.wv-mb-heading {
  font-size: 26px;
  margin-bottom: 30px;
  display: none;
}

.wv-font-size16 {
  font-size: 16px;
}

.wv-font-size18 {
  font-size: 18px;
}

.wv-img-wrap-proicon {
  width: 42px;
  height: 42px;
}

.wv-img-wrap-proicon-70 {
  width: 65px;
  max-height: 65px;
  min-height: 70px;
}

.wv-img-wrap-proicon-70 img {
  width: auto;
}

img {
  width: 100%;
}

.wv-social-link a {
  display: inline-block;
}

.wv-social-link img {
  filter: brightness(0.5);
  width: 15px;
  height: 15px;
  margin-left: 7px;
}

.wv-mb-icon {
  display: none;
}

.wv-mb-icon span {
  background-color: #ff7900;
  display: inline-block;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  vertical-align: sub;
  margin-left: 5px;
  transition: ease 0.3s;
}

.wv-mb-icon img {
  float: right;
  width: 12px;
  position: relative;
  left: -4px;
  transition: ease 0.3s;
  top: 3px;
}

.wv-top-nav {
  font-size: 16px;
  color: #4095e9;
  text-decoration: underline;
  display: none;
}

.wv-company-name {
  font-family: "Roboto-Regular";
  padding-top: 20px;
  font-weight: 800;
  letter-spacing: 0.5px;
  font-size: 24px;
  color: #5f6367;
}

.wv-header-logo {
  padding-top: 0;
  position: absolute;
  left: 58px;
  top: 2px;
  font-size: 32px;
}

.wv-footer-logo {
  width: 32px;
  display: inline-block;
  vertical-align: bottom;
}

.wv-logo-width {
  width: 40px;
}

.overflow-hidden {
  overflow-y: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.wv-company-name:hover {
  color: #5f6367;
  text-decoration: none;
}

.wv-landing-logo-color {
  color: #adadad;
}

.wv-landing-logo-color:hover {
  color: #adadad;
}

/* header */
.wv-brand-name {
  font-size: 26px;
  font-family: "Roboto-Regular";
  font-weight: 800;
  letter-spacing: 0.5px;
  color: #314af2;
}

.wv-brand-logo {
  font-family: "Roboto-Regular";
  margin: 0;
  font-size: 18px;
  color: #adadad;
  letter-spacing: 0.5px;
  font-weight: 800;
}

.wv-brand-extra {
  position: absolute;
  top: 6px;
  padding-left: 35px;
}

.wv-header a.wv-pt0 {
  padding-top: 0;
}

.hsub {
  left: -2px;
  color: #adadad;
}

.fsub {
  color: #adadad;
  top: -6px;
}

.ffsub {
  left: -5px;
}

.bsub {
  top: -16px;
  left: 3px;
  color: #fff;
  font-size: 16px;
}

.lfsub {
  left: 2px;
  top: -11px;
  color: #333;
  font-size: 16px;
}

.fbsub {
  color: #adadad;
  left: -5px;
}

.--bg-body-content h4.wv-le14 {
  left: 14%;
}

.--bg-body-content h4.full-banner-text-call {
  left: 14%;
  color: #d0fff4;
}

.wv-banner-text-medium {
  font-size: 30px;
  line-height: 42px;
  font-family: "Roboto-Regular";
  font-weight: bolder;
  letter-spacing: -0.6px;
  width: 75%;
  margin: 0 auto;
}

.wv-number {
  width: 24px;
  height: 24px;
  background-color: #ff0000;
  display: inline-block;
  border-radius: 100%;
  text-align: center;
  line-height: 26px;
  color: #fff;
  font-size: 16px;
}

.wv-banner-img-scroll {
  position: relative;
  top: -8%;
  margin-bottom: 220px;
}

/* .wv-banner-img-scroll .container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-image: url("../img/how-it-works/line.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: -18px;
} */

.wv-banner-img-scroll .col {
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
}

.wv-banner-img-scroll .col:hover h4 {
  color: #4095e9;
}

.wv-banner-img-scroll .img-responsive img {
  max-height: 120px;
}

#wv-navigation .btn {
  min-width: 70%;
}

@media (min-width: 576px) {
  .wv-footer .container {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .wv-footer .container {
    max-width: 95%;
  }
}

@media (min-width: 992px) {
  .wv-footer .container {
    max-width: 95%;
  }
}

@media (min-width: 1200px) {
  .wv-footer .container {
    max-width: 1150px;
  }
}

@media screen and (max-width: 740px) {
  .wv-feature-content .wv-box-content {
    flex: auto;
    max-width: 100%;
    margin-bottom: 20px;
    padding: 25px;
  }

  .wv-feature-content .wv-box-content:last-child {
    margin-bottom: 0;
  }

  .wv-mb-icon {
    display: inline-block;
    padding: 7px 0;
    border-radius: 20px;
    font-size: 13px;
    position: absolute;
    top: -3px;
    min-width: 60px;
    right: 14px;
    letter-spacing: 0.5px;
    color: #ff7900;
    font-weight: 500;
  }

  .wv-mb-icon.--active span {
    transform: rotate(-180deg);
    transition: ease 0.3s;
    background-color: #e65100;
  }

  .wv-mb-icon.--active {
    color: #e65100;
  }

  .wv-nav ul {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    background-color: #fff;
    display: none;
    opacity: 0;
    transition: ease 0.3s;
    padding: 20px 15px;
    text-align: left;
  }

  #wv-navigation {
    background: #f8f9fa 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 5px #9e9e9e40;
    text-align: center;
  }

  .wv-nav ul.--active {
    opacity: 1;
    display: block;
    left: 0;
    right: 0;
    top: 110px;
    width: 100%;
    background: #fff;
    z-index: 6;
    transition: ease 0.3s;
  }

  .wv-nav ul li {
    display: block;
    padding: 15px 0;
    margin: 0 10px;
  }

  .wv-nav ul li:last-child {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 740px) {
  .wv-tab-menu {
    display: none;
  }

  .--hd-sm {
    display: none;
  }

  .--sh-sm {
    display: block !important;
  }

  .wv-mb-heading {
    display: block;
  }

  .wv-img-content .__data {
    padding: 30px 20px;
  }

  .wv-img-content {
    border-bottom: none;
  }

  .wv-img-content .__data:first-child {
    border-right: none;
  }

  .wv-img-content .__data {
    border-bottom: 1px solid #ebebeb;
  }

  .wv-first-section .__content,
  .wv-banner .__content {
    width: 450px;
    padding: 20px 35px;
  }

  /* .wv-banner {
    margin-top: 110px;
  } */

  /*feature page*/

  .wv-md-heading {
    font-size: 14px;
    line-height: 24px;
  }

  .wv-heading-h2 {
    font-size: 20px;
    line-height: 18px;
  }

  .wv-heading-h4 {
    font-size: 22px;
    line-height: 28px;
    color: #333333;
    font-family: "Roboto-Medium";
    margin: 0;
    margin-bottom: 20px !important;
  }

  .wv-heading-h5 {
    font-size: 14px;
    line-height: 24px;
  }

  .wv-heading-h3 {
    font-size: 24px;
    line-height: 28px;
  }

  .wv-lg-heading {
    font-size: 28px;
    line-height: 40px;
  }

  .wv-points-list li {
    font-size: 14px;
    line-height: 18px;
  }

  /* .wv-footer {
    padding: 20px 0 30px 0;
  } */

  .wv-border-botton-footer {
    padding-bottom: 10px !important;
    /* border-bottom: 1px solid #dadce0; */
  }

  .wv-img-content .__data > p {
    margin-bottom: 20px;
    min-height: auto;
  }

  .wv-img-content .__data:last-child {
    border: none;
  }

  .wv-content-area {
    padding: 20px 0;
  }

  .wv-ico-content {
    padding-right: 15px;
  }

  .wv-banner .__content > h4 {
    font-size: 28px;
    line-height: 40px;
  }

  .wv-mb-heading {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .wv-ico-content h5 {
    font-size: 18px;
    line-height: 24px;
    margin-top: 10px;
  }

  .wv-img-content .__data > p {
    font-size: 14px;
    line-height: 24px;
  }

  .wv-img-content .__data > a {
    font-size: 14px;
  }

  .wv-banner .__content > p,
  .wv-first-section .__content > p {
    font-size: 16px;
    line-height: 30px;
  }

  .wv-banner .container {
    padding: 60px 0px;
    position: relative;
  }

  .--quick-link h4 {
    font-weight: 500;
  }

  .wv-header a {
    font-size: 12px;
  }

  .wv-logo {
    max-width: 120px;
  }
}

@media screen and (max-width: 480px) {
  .wv-banner .wv-lg-heading {
    font-size: 22px;
    line-height: 38px;
  }

  .wv-banner .__content > h4 {
    font-size: 22px;
    line-height: 38px;
  }

  .wv-banner .__content > p,
  .wv-first-section .__content > p {
    font-size: 16px;
    line-height: 30px;
  }

  .wv-img-content .__data > p {
    font-size: 14px;
    min-height: auto;
  }

  .wv-ico-content h5 {
    font-size: 18px;
    margin-top: 10px;
  }

  .wv-mb-heading {
    font-size: 20px;
  }

  .wv-first-section .__content,
  .wv-banner .__content {
    width: 405px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .wv-content-area {
    padding: 10px 5px;
  }

  .footer_content_between_row .--quick-link {
    margin: 10px 0;
    width: 100%;
  }

  .--footer-sublink ul li {
    display: block;
  }

  .--footer-sublink ul li {
    margin: 0;
  }

  .sm-align-left {
    text-align: left !important;
  }
}

@media screen and (max-width: 440px) {
  .wv-first-section .__content,
  .wv-banner .__content {
    width: 320px;
  }

  .wv-content-area {
    padding: 10px 5px;
  }
}

@media screen and (max-width: 340px) {
  .wv-first-section .__content,
  .wv-banner .__content {
    width: 280px;
    padding: 12px 15px;
  }
}

.m_t_110 {
  margin-top: 114px;
}

.footer-content a img {
  width: 220px;
  padding-left: 0px;
  margin-bottom: 30px;
  padding-right: 40px;
}

@media (min-width: 481px) and (max-width: 768px) {
  .footer-content {
    text-align: center;
  }

  .footer-content a img {
    margin-bottom: 0px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .wv-box-content {
    padding: 25px !important;
  }

  .justify-content-center.ml-0.mr-0 {
    margin-top: 20%;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
}
